import type { GetCustomerResponse, UpdateCustomerRequest, GetAddressesResponse, UpdateAddressRequest, RemoveAddressRequest, CreateAddressRequest, GetOrdersResponse } from '@vue-storefront/prestashop-api';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { useSdk } from '~/sdk';

export const useAuth = defineStore('auth', () => {
  const user: Ref<GetCustomerResponse['psdata'] | null> = ref(null);
  const addresses: Ref<GetAddressesResponse['psdata'] | null> = ref(null)
  const orders: Ref<GetOrdersResponse['psdata'] | null> = ref(null)
  const loading = ref(false);
  const isAuthenticated = computed(() => !!user.value);

  const refresh = async () => {
    loading.value = true;
    user.value = await useSdk().commerce.getCustomer();
    loading.value = false;
  }

 const logout = async () => {
    await useSdk().commerce.logout();
    user.value = null;
    await refresh();
  }

  const loadCustomerOrders = async () => {
    loading.value = true
    orders.value = await useSdk().commerce.getOrders()
    loading.value = false
  }

  return {
    user,
    loading,
    isAuthenticated,
    addresses,
    orders,
    refresh,
    execute: refresh,
    logout,
    loadCustomerOrders
  };
  
});